import gsap from "gsap";

document.querySelectorAll("[data-image-scroll]").forEach((element) => {
    const picture = element.querySelector("picture");

    if (!picture || innerWidth < 1024) {
        return;
    }

    gsap.fromTo(
        picture,
        {
            y: "4rem",
        },
        {
            y: "-4rem",
            scrollTrigger: {
                trigger: element,
                start: "top center",
                end: "bottom center",
                scrub: 0.2,
            },
        }
    );
});
