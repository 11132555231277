import gsap from "gsap";
import {spanSplit} from "../helpers/span-split";

function detect(entries, observer) {
  for (let entry of entries) {
    if (entry.isIntersecting) {
      animate(entry.target);
      observer.unobserve(entry.target);
    }
  }
}

function animate(element) {
  const spans = element.querySelectorAll("span");

  const duration = element.dataset.textReveal ? element.dataset.textReveal : "0.5";
  const stagger = duration / 5;

  gsap.to(spans, {
    yPercent: 0,
    stagger,
    rotate: 0,
    duration,
    ease: "power2.out",
  });
}

const options = {
  threshold: 0.5,
};

if(checkBrowserSupport()){
    window.textRevealObserver = new IntersectionObserver(detect, options);
    
    document.querySelectorAll("[data-text-reveal]").forEach((element) => {
      const spans = spanSplit(element);
      gsap.set(spans, {
        yPercent: 110,
        rotate: 5,
      });
    
      window.textRevealObserver.unobserve(element);
      window.textRevealObserver.observe(element);  
    });
}


function checkBrowserSupport(){
    return (
        CSS.supports("clip-path", "polygon(0 -100%, 100% -100%, 115% 100%, 0% 115%)") &&
        CSS.supports("height", "1lh")
    );
}