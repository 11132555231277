export function spanSplit(element, divWrapper = true, textGetter = 'innerHTML') {
    const words = element[textGetter].split(" ");
    
    element.innerHTML = "";

    const parts = [];

    words.forEach((word) => {
        if (!word.includes("<br>\n")) {
            parts.push(word);
            return;
        }

        const splits = word.split("<br>\n");
        parts.push(splits[0]);
        parts.push("<br>");
        parts.push(splits[1]);
    });

    const spans = [];

    parts.forEach((word, i) => {
        if (word === "<br>") {
            element.appendChild(document.createElement("br"));
            return;
        }

        const div = document.createElement("div");
        const span = document.createElement("span");

        if (i < parts.length - 1) {
            span.innerHTML = word + "&nbsp;";
        } else {
            span.innerHTML = word;
        }

        spans.push(span);

        if(divWrapper){
            div.appendChild(span);
            element.appendChild(div);
        }else{
            element.appendChild(span);
        }

    });

    return spans;
}
