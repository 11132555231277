import GdprConsent from "../../../../../vendor/acc-digital/pim-websitebundle/bundles/AccWebsiteBundle/Resources/views/components/GdprConsent/GdprConsent.js";

const gdprNode = document.querySelector('[data-component="GdprConsent"]');

if (gdprNode) {
    let props = gdprNode.getAttribute("data-props");
    if (props) props = JSON.parse(props);

    new GdprConsent(gdprNode, props);
}
